<template>
    <div :class="[$style.filter]" v-if="currentFilter" v-loading="loading">
        <div :class="$style['filter-arrow']" ref="filterArrow"></div>

        <div :class="[$style['filter-container'], 'x-wrap x-wrap--full']">
            <div :class="$style['filter-tips']">
                {{ $t('users.filter_setting_description') }}
            </div>

            <template v-if="currentFilter.children && userGroupType">
                <filter-content
                    v-for="(filter, index) in currentFilter.children"
                    :key="filter.uuid"
                    :sid="sid"
                    :timeZone="timeZone"
                    :filter="filter"
                    :filter-len="currentFilter.children && currentFilter.children.length"
                    :filter-items="userGroupType"
                    :value-options="valueOptions"
                    @updateItems="handleFilterItemsUpdate"
                    @delete="val => deleteFilter(index, val)"
                    @change="val => handleFilterChange(index, val)"
                ></filter-content>
            </template>

            <a :class="$style['filter-add']" @click="handleFilterCreate">{{ $t('users.add_condition') }}</a>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import commonUtils from '@/common/utils/common.utils';
import cloneUtils from '@/common/utils/clone.utils';
import uuidUtil from '@/common/utils/uuid.utils';
import FilterContent from './content/Filter';
import userGroupConfig from '../../usergrp-config';
import { searchTree, getDefaultValue, initUsergroupData } from '../../usergrp-util';

export default {
    name: 'FilterGroup',

    props: {
        filter: Object,
        filterTmp: Object,
        filterVisible: Boolean,
        userGroupId: String
    },

    data() {
        return {
            loading: true,
            currentFilter: null,
            filterEditing: false,
            userGroupChanged: false,
            userGroupType: null,
            valueOptions: {}
        };
    },

    created() {
        const userProperties = this.userProperties ? this.setUserPropertyName(this.userProperties) : [];
        const basicFilterItems = cloneUtils.deep(userGroupConfig).audienceType.map(item => {
            const { label, options } = item;
            return {
                label,
                options: options.map(o => {
                    const { code, matchRange } = o;
                    o.code = `${code}_${matchRange}`;
                    o.key = code;
                    return o;
                })
            };
        });
        this.userGroupType = [...basicFilterItems, userProperties];
        this.loading = false;
        window.addEventListener('resize', this.handleResize, false);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize, false);
    },

    mounted() {
        this.updateIconPosition();
        this.filterEditing = this.filter.children && this.filter.children.length > 0;
        this.currentFilter = Object.assign({ children: [], operator: 'and' }, initUsergroupData(this.filter));
    },

    computed: {
        ...mapState('main', ['profileInfo']),
        ...mapState('users', ['userProperties']),

        sid() {
            return this.profileInfo.sid;
        },

        timeZone() {
            return this.profileInfo.timeZone;
        }
    },

    methods: {
        ...mapMutations('main', ['UPDATE_PACKAGE_USAGE']),

        _groupBy(array, f) {
            let groups = {};
            array.forEach(function(o) {
                let group = f(o);
                groups[group] = groups[group] || [];
                groups[group].push(o);
            });
            // return Object.keys(groups).map(function(group) {
            //     return groups[group];
            // });
            return groups;
        },

        /**
         * 获取用户属性列表
         */
        setUserPropertyName(options = []) {
            return {
                label: this.$t('dimension.user_properties'),
                options
            };
        },

        toggleFilterClick() {
            this.filterEditing = !this.filterEditing;
        },

        // 更新字段字典表
        handleFilterItemsUpdate(code, value) {
            this.$set(this.valueOptions, code, value);
        },

        handleUnfold() {
            this.$emit('unfold');
        },

        handleFilterChange(index, filter) {
            this.userGroupChanged = true;
            this.$set(this.currentFilter.children, index, filter);
            this.notice();
        },

        handleResize() {
            this.updateIconPosition();
        },

        handleRestClick() {
            this.userGroupChanged = false;
            this.$set(this.currentFilter, 'children', cloneUtils.deep(this.filterTmp.children));
            this.notice();
        },

        notice() {
            const { children } = this.currentFilter || { children: [] };
            (!children || children.length === 0) && (this.currentFilter = {});
            this.$emit('change', this.currentFilter);
        },

        handleFilterCreate() {
            let defaultValue = getDefaultValue();
            let filters = [].concat(this.currentFilter.children || []);
            filters.push(defaultValue);
            this.$set(this.currentFilter, 'children', filters);
            this.userGroupChanged = true;
        },

        async saveFilter() {
            const isCreate = this.userGroupId === 'create';
            let userFiltersQuery = usersApis.createUsergroup(null, {
                data: {
                    sid: this.sid,
                    name: 'testAudience',
                    description: 'test for dev',
                    content: this.currentFilter
                }
            });

            if (isCreate) {
                let userFiltersQuery = usersApis.createUsergroup(null, {
                    where: {
                        id: this.userGroupId
                    },
                    data: {
                        content: this.currentFilter
                    }
                });
            }
            const [err, res] = await commonUtils.awaitWrap(userFiltersQuery);
            if (err) {
                this.$message.error(this.$t('error.save_user_group'));
            } else {
                if (isCreate) {
                    let currentFilterId = res.id;
                    typeof res?.count === 'number' &&
                        this.UPDATE_PACKAGE_USAGE({ type: 'userGroups', count: res.count });
                    this.$emit('created', currentFilterId);
                    this.$emit('change', res.content);
                }
            }
        },

        deleteFilter(index, val) {
            this.currentFilter.children.splice(index, 1);
            this.userGroupChanged = true;
            this.notice();
        },

        updateIconPosition() {
            const filterIcon = document.getElementById('js_filterIcon');
            const arrowDom = this.$refs.filterArrow;
            const offset = -3; //对准中心偏移量
            filterIcon && arrowDom && (arrowDom.style.left = filterIcon.offsetLeft + offset + 'px');
        }
    },

    watch: {
        filter(val) {
            this.currentFilter = initUsergroupData(this.filter);
        },
        filterVisible(val) {
            val && this.updateIconPosition();
            this.$eventBus.$emit('handleReportFilter', val);
        }
    },

    components: {
        FilterContent
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

$arrow-height: 16px;

.filter {
    margin: 16px 0 32px;
    overflow: visible !important;
    position: relative;

    &-container {
        padding: 24px 12px;
        border-radius: 4px;
        border: 1px solid $pt-black-50;
        background-color: $pt-gray-10;
        position: relative;
        min-width: 222px;
    }

    &-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 11px ($arrow-height + 1px) 11px;
        border-color: transparent transparent $pt-black-50 transparent;
        position: absolute;
        top: -$arrow-height;
        left: 20px;
        z-index: 1;

        &::after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px $arrow-height 10px;
            border-color: transparent transparent $pt-gray-10 transparent;
            position: absolute;
            top: 1px;
            left: -10px;
        }
    }

    &-close {
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;

        &:hover {
            fill: $pt-black-90 !important;
        }
    }

    &-tips {
        color: $pt-black-600;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 10px;
    }

    &-add {
        margin-top: 12px;
        @include link($pt-green-60, $pt-green-70);
    }
}
</style>
