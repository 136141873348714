<template>
    <div :class="$style.nameEditor">
        <pt-input
            v-if="canEdit && onFocus"
            ref="inputbox"
            size="large"
            inputStyle="underline"
            :class="$style.edit"
            :maxlength="maxLen"
            @blur="blur($event)"
            @keyup.enter="blur($event)"
            v-model.trim="currentName"
        ></pt-input>
        <div :class="[$style.name, canEdit || $style.readonly]" @click="letFocus" v-else>
            <span>{{ currentName }}</span>
            <div :class="$style.icon">
                <pt-icon
                    icon="pt-icon--pen"
                    :icon-style="{
                        width: '18px',
                        height: '18px'
                    }"
                ></pt-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NameEditor',

    props: {
        name: String,
        canEdit: {
            type: Boolean,
            default: function() {
                return true;
            }
        }
    },

    data() {
        const hasName = Boolean(this.name);
        return {
            onFocus: !hasName,
            maxLen: 50,
            currentName: this.name,
            lastSaveName: this.name
        };
    },

    methods: {
        letFocus() {
            this.focus();
            this.$nextTick(() => {
                this.$refs.inputbox && this.$refs.inputbox.focus();
            });
        },

        blur(event) {
            this.onFocus = false;
            if (event.target.value === '') {
                this.currentName = this.lastSaveName;
                return;
            }
            this.currentName = event.target.value;
            this.notice();
        },

        focus() {
            this.onFocus = true;
        },

        notice() {
            this.$emit('change', this.currentName);
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import';
.nameEditor {
    color: $pt-black-600;
    font-size: 20px;
    font-weight: 700;
    flex: 0 1 70%;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 70%;
    margin: 0px 0px;

    .name {
        @extend %text-ellipsis;
        max-width: 250px;
        margin: 0 6px;
        min-width: 38px;
        position: relative;
        cursor: text;
        transition: margin-right 0.4s ease;

        &:not(.readonly):hover {
            border-bottom: 1px solid $pt-black-50;

            .icon {
                display: flex;
            }
        }
    }

    .icon {
        height: 100%;
        background-color: $pt-white;
        position: absolute;
        bottom: 0px;
        right: 0;
        align-items: center;
        display: none;

        svg {
            text-align: center;
            vertical-align: top;
            fill: $pt-black;
        }
    }

    .edit {
        width: 250px;
    }
}
</style>
