<template>
    <div :class="$style.setting" v-loading="loading">
        <template v-if="!loading">
            <page-header size="full" :visibleBack="true" :class="$style.header" @back="handleBackClick">
                <name-editor
                    slot="title"
                    :name="userGroupName"
                    :canEdit="!usersIsDefault && canUpdateUserGroup"
                    @change="handleNameChange"
                ></name-editor>
                <a
                    id="js_filterIcon"
                    slot="leftTools"
                    :class="[$style.filterBtn, filterVisible && $style.show]"
                    @click="handleToggleFilterClick"
                >
                    <pt-icon
                        icon="pt-icon--filter"
                        :icon-style="{
                            width: '16px',
                            height: '16px'
                        }"
                    ></pt-icon>
                    <span>{{ filtersText }}</span>
                </a>

                <div :class="$style.rightTools" slot="rightTools">
                    <pt-button
                        type="outline"
                        size="medium"
                        v-role="{
                            type: 'saveUsergroup',
                            role: profileInfo.currentUserRole
                        }"
                        v-if="visibleSaveBtn && !usersIsDefault && canUpdateUserGroup"
                        :loading="saving"
                        :disabled="!usersCanSave"
                        @click="saveUserGroup('edit')"
                    >
                        {{ $t('users.save_users') }}
                    </pt-button>
                    <verify-package
                        privilege-key="CREATE_USER_GROUP"
                        component="link"
                        v-role="{
                            type: 'saveNewUsergroup',
                            role: profileInfo.currentUserRole
                        }"
                        :resolveCallback="() => toggleDialogVisible('details', true)"
                        :rejectCallback="showAuthDialog"
                    >
                        <pt-button
                            size="medium"
                            v-if="visibleSaveBtn && canUpdateUserGroup"
                            :loading="saving"
                            :disabled="!usersCanSave"
                        >
                            {{ $t('users.save_new_users') }}
                        </pt-button>
                    </verify-package>
                </div>
            </page-header>

            <div :class="$style.body">
                <div :class="$style.container">
                    <filter-group
                        v-show="filterVisible"
                        :filterVisible="filterVisible"
                        :userGroupId="userGroupId"
                        :filter="usersFilter"
                        :filterTmp="userGroupInfoTmp && userGroupInfoTmp.content"
                        @unfold="handleFilterUnfold"
                        @change="handleFilterChange"
                    ></filter-group>

                    <div :class="['x-wrap', 'x-wrap--full', $style.tabs]">
                        <pt-tabs :tabs="tabOptions" label="code" theme="blue" size="large" v-model="currentTabCode">
                            <template v-slot="slotProps">
                                <router-link :to="{ name: slotProps.tab.routerName, params: $route.params }">
                                    {{ $t(slotProps.tab.name) }}
                                </router-link>
                            </template>
                        </pt-tabs>
                    </div>
                </div>

                <keep-alive>
                    <router-view></router-view>
                </keep-alive>
            </div>
        </template>

        <!-- 详情确认弹框-->
        <details-dialog
            v-if="dialogVisible.details"
            :userGroupInfo="userGroupInfo"
            @cancel="toggleDialogVisible('details', false)"
            @confirm="handleConfirmSave"
        ></details-dialog>

        <!-- 确认返回弹框-->
        <back-dialog
            v-if="dialogVisible.backDialog"
            @cancel="toggleDialogVisible('backDialog', false)"
            @confirm="handleConfirmBack"
        ></back-dialog>
    </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
import FilterGroup from './filter/FilterGroup';
import DetailsDialog from './dialogs/DetailsDialog';
import BackDialog from './dialogs/BackDialog';
import usersApis from '@/apis/users.apis';
import commonUtils from '@/common/utils/common.utils';
import cloneUtils from '@/common/utils/clone.utils';
import analyticsService from '@/common/services/analytics.service';
import NameEditor from '@/common/packages/name-editor/NameEditor';
import PageHeader from '@/common/packages/page-header/PageHeader';
import VerifyPackage from '@/common/packages/verify-package/VerifyPackage';
import { checkModuleRole } from '@/common/services/singleSpa.service';

export default {
    name: 'UsersSetting',

    data() {
        const tabOptions = [
            {
                code: 'overview',
                default: true,
                name: this.$t('users.overview_title'),
                routerName: 'UsersSettingOverview'
            },
            {
                code: 'report',
                name: this.$t('users.behavior_report_title'),
                routerName: 'UsersSettingReport'
            },
            {
                code: 'users',
                name: this.$t('users.users_list_title'),
                routerName: 'UsersSettingList'
            }
        ];
        const defaultTab = tabOptions.find(tab => tab.default);
        const userGroupId = this.$route.params.usersId;
        const dialogVisible = {
            details: false,
            backDialog: false
        };

        return {
            from: this.$route.params.from,
            loading: true,
            saving: false,
            tabOptions,
            filterVisible: false,
            userGroupId,
            userGroupInfo: null,
            userGroupInfoTmp: null, //原始值
            dialogVisible,
            canUpdateUserGroup: checkModuleRole('userGroupUpdate'),
            details: {
                name: '',
                description: ''
            }
        };
    },

    computed: {
        ...mapState('main', ['profileInfo']),
        ...mapGetters('users', ['usersFilter']),

        /**
         * 用户组名称
         */
        userGroupName() {
            if (!this.userGroupInfo) return '';

            const { content, guideType } = this.userGroupInfo;

            // 所有用户
            const isAllPeople = Object.keys(content || {}).length === 0 && this.usersIsDefault && 'users.all_people';

            // 匿名用户
            const isAnonymous =
                guideType === 'anonymous_users' &&
                content?.children?.[0].uuid === 'a4b2cfb0-724c-433d-8cd6-0580d7ad9aad' &&
                'users.anonymous_name';

            // 实名用户
            const isKnown =
                guideType === 'known_users' &&
                content?.children?.[0].uuid === 'e8ff309a-8995-4960-bc7a-f425955d853a' &&
                'users.known_name';

            // 忠实用户
            const isLoyal =
                guideType === 'loyal_users' &&
                content?.children?.[0].uuid === 'ab145672-a5cc-42f0-b885-0686aa80b1e4' &&
                'users.loyal_name';

            const i18nKey = isAllPeople || isAnonymous || isKnown || isLoyal;
            return i18nKey ? this.$t(i18nKey) : this.userGroupInfo.name;
        },

        // 预制面板不可编辑名称
        usersIsDefault() {
            return this.userGroupInfo.source === 'default';
        },

        // 是否为 All people
        usersIsAllPeople() {
            return Object.keys(this.userGroupInfo.content || {}).length === 0 && this.usersIsDefault;
        },

        visibleSaveBtn() {
            return !isEqual(this.userGroupInfoTmp.content, this.usersFilter);
        },

        usersCanSave() {
            const children = (this.currentContent && this.currentContent.children) || [];
            return children.length > 0;
        },

        //过滤值，去除不完善或者错误的过滤条件
        currentContent() {
            const { content } = this.userGroupInfo || {};
            const { children = [], operator = 'and' } = content || {};
            return {
                children: children.reduce((acc, cur) => {
                    const { error = {} } = cur || {};
                    const hasError = Object.keys(error).some(key => error[key]);
                    hasError || acc.push(cur);
                    return acc;
                }, []),
                operator
            };
        },

        filtersText() {
            const len =
                (this.currentContent && this.currentContent.children && this.currentContent.children.length) || 0;
            return len > 0 ? this.$t('users.filters', { len }) : this.$t('users.filter_users');
        },

        currentTabCode() {
            const tab = this.tabOptions.find(tab => tab.routerName === this.$route.name) || this.defaultTab;
            switch (tab.code) {
                case 'overview':
                    analyticsService.usePTX('ug_report_view', { report_category: 'overview' }).track();
                    break;
                case 'report':
                    analyticsService.usePTX('ug_report_view', { report_category: 'behaviour' }).track();
                    break;
                case 'users':
                    analyticsService.usePTX('ug_report_view', { report_category: 'user_list' }).track();
                    break;
            }
            return tab.code;
        }
    },

    async mounted() {
        const [err, properties] = await commonUtils.awaitWrap(this.getUsersProperties());
        if (err || !properties) this.handleConfirmBack();

        const [errInfo, data] = await commonUtils.awaitWrap(this.fetchUserGroupInfo());
        errInfo ? this.handleConfirmBack() : (this.userGroupInfo = data);

        if (this.userGroupInfo) {
            const { name, description } = this.userGroupInfo || {};
            this.userGroupInfoTmp = cloneUtils.deep(this.userGroupInfo);
            this.UPDATE_USER_GROUP_INFO(this.userGroupInfo);
            this.UPDATE_USER_GROUP_NAME(name);
            this.loading = false;
        } else {
            this.handleConfirmBack();
        }
    },

    methods: {
        ...mapActions('users', ['getUsersProperties']),
        ...mapMutations('users', ['UPDATE_USER_GROUP_INFO', 'UPDATE_USER_GROUP_NAME', 'UPDATE_USER_PROERTIES']),
        ...mapMutations('main', ['SET_AUTH_DIALOG_VISIBLE', 'UPDATE_PACKAGE_USAGE']),

        fetchUserGroupInfo() {
            return usersApis.getUserGroupInfo(null, {
                where: {
                    sid: this.profileInfo.sid,
                    id: this.userGroupId
                }
            });
        },

        fetchPropertiesValue() {
            const { sid, timeZone } = this.profileInfo;
            return usersApis.getDictData(null, {
                where: {
                    sid,
                    funName: 'userPropertyName',
                    timeZone,
                    limit: 100
                }
            });
        },

        handleFilterUnfold() {
            this.filterVisible = false;
        },

        handleFilterChange(filter) {
            this.$set(this.userGroupInfo, 'content', filter);
            this.UPDATE_USER_GROUP_INFO(this.userGroupInfo);
        },

        handleToggleFilterClick() {
            this.filterVisible = !this.filterVisible;
            this.$nextTick(() => {
                this.$refs.userSettingScrollbar && this.$refs.userSettingScrollbar.scrollToPos('top');
            });
        },

        handleBackClick() {
            if (!this.usersIsDefault && this.visibleSaveBtn && this.canUpdateUserGroup) {
                this.toggleDialogVisible('backDialog', true);
            } else {
                this.handleConfirmBack();
            }
        },

        handleNameChange(name) {
            this.userGroupInfo.name = name;
            this.updateUsergroup(true);
        },

        handleConfirmSave({ name, description }) {
            this.userGroupInfo.name = name;
            this.userGroupInfo.description = description;
            this.saveUserGroup('create');
        },

        /**
         * 返回事件
         */
        handleConfirmBack() {
            this.$router.push({
                name: this.from || 'UsersReport',
                params: { engageId: this.$route.params.engageId }
            });
        },

        toggleDialogVisible(type, visible) {
            if (visible && type == 'details') {
                analyticsService.usePTX('ug_add_click', { position: 'ug_edit_save_as_new' }).track();
            }
            this.$set(this.dialogVisible, type, visible);
        },

        createUsergroup() {
            const { name, description } = this.userGroupInfo;
            return usersApis.createUsergroup(null, {
                data: {
                    sid: this.profileInfo.sid,
                    name,
                    content: this.currentContent,
                    description
                }
            });
        },

        updateUsergroup(onlyUpdateName = false) {
            const { id, name, content } = this.userGroupInfo;
            const data = {
                name,
                content: this.currentContent
            };
            onlyUpdateName && delete data.content;
            return usersApis.updateUsergroup(null, {
                data,
                where: {
                    id,
                    sid: this.profileInfo.sid
                }
            });
        },

        /**
         * @param type: edit || create
         */
        async saveUserGroup(type) {
            const mapper = {
                create: this.createUsergroup,
                edit: this.updateUsergroup
            };
            this.saving = true;
            const [err, data] = await commonUtils.awaitWrap(mapper[type]());
            if (err) {
                if (err.code && err.code === 'PTX_PACKAGE_LIMIT_ERROR') {
                    const authType = type === 'create' ? 'CREATE_USER_GROUP' : 'EDIT_USER_GROUP';
                    this.showAuthDialog(authType);
                } else {
                    this.$message.error('save user group failed.');
                }
            } else {
                type === 'create' &&
                    (data?.count && this.UPDATE_PACKAGE_USAGE({ type: 'userGroups', count: data.count }),
                    analyticsService.usePTX('ug_add_success', { position: 'ug_edit_save_as_new' }).track());
                this.$router.push({ name: this.from || 'UsersReport' });
            }
            type === 'edit' && analyticsService.usePTX('ug_edit', { position: 'ug_save_filter' }).track();
            this.saving = false;
        },
        showAuthDialog(type = 'CREATE_USER_GROUP') {
            this.SET_AUTH_DIALOG_VISIBLE({ visible: true, type: type });
        }
    },

    components: {
        BackDialog,
        DetailsDialog,
        FilterGroup,
        NameEditor,
        PageHeader,
        VerifyPackage
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';
:global(.fromEngage) {
    position: fixed;
    left: 0;
    width: 100%;
    background-color: #fff;
}

.setting {
    .leftTools {
    }

    .rightTools {
        button {
            margin-left: 12px;
        }
    }

    .header {
        border-bottom: none;
        margin-bottom: 0;

        :global {
            .page-header_title-left {
                flex: 0;
            }
        }
    }

    .filterBtn {
        white-space: nowrap;
        font-size: 13px;
        display: flex;
        align-items: center;
        @include link($pt-green-60, $pt-green-70);
        text-decoration: none;

        &.show {
            svg {
                fill: $pt-green-60;
            }
        }
    }

    .body {
        flex: 1;
        overflow: hidden;
        margin-top: -16px;
    }

    .container {
        padding-top: 16px;
        margin-bottom: 32px;
        background-color: $pt-white;
        border-bottom: 1px solid $pt-black-40;
    }

    .content {
        flex: 1;
        overflow: hidden;
    }

    .tabs {
        height: 48px;
        display: flex;
        justify-content: flex-start;

        :global {
            .pt-tabs__nav {
                padding: 0;
                border-bottom: none;
            }
        }
    }
}
</style>
