var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:_vm.$style.setting},[(!_vm.loading)?[_c('page-header',{class:_vm.$style.header,attrs:{"size":"full","visibleBack":true},on:{"back":_vm.handleBackClick}},[_c('name-editor',{attrs:{"slot":"title","name":_vm.userGroupName,"canEdit":!_vm.usersIsDefault && _vm.canUpdateUserGroup},on:{"change":_vm.handleNameChange},slot:"title"}),_c('a',{class:[_vm.$style.filterBtn, _vm.filterVisible && _vm.$style.show],attrs:{"slot":"leftTools","id":"js_filterIcon"},on:{"click":_vm.handleToggleFilterClick},slot:"leftTools"},[_c('pt-icon',{attrs:{"icon":"pt-icon--filter","icon-style":{
                        width: '16px',
                        height: '16px'
                    }}}),_c('span',[_vm._v(_vm._s(_vm.filtersText))])],1),_c('div',{class:_vm.$style.rightTools,attrs:{"slot":"rightTools"},slot:"rightTools"},[(_vm.visibleSaveBtn && !_vm.usersIsDefault && _vm.canUpdateUserGroup)?_c('pt-button',{directives:[{name:"role",rawName:"v-role",value:({
                        type: 'saveUsergroup',
                        role: _vm.profileInfo.currentUserRole
                    }),expression:"{\n                        type: 'saveUsergroup',\n                        role: profileInfo.currentUserRole\n                    }"}],attrs:{"type":"outline","size":"medium","loading":_vm.saving,"disabled":!_vm.usersCanSave},on:{"click":function($event){return _vm.saveUserGroup('edit')}}},[_vm._v(" "+_vm._s(_vm.$t('users.save_users'))+" ")]):_vm._e(),_c('verify-package',{directives:[{name:"role",rawName:"v-role",value:({
                        type: 'saveNewUsergroup',
                        role: _vm.profileInfo.currentUserRole
                    }),expression:"{\n                        type: 'saveNewUsergroup',\n                        role: profileInfo.currentUserRole\n                    }"}],attrs:{"privilege-key":"CREATE_USER_GROUP","component":"link","resolveCallback":function () { return _vm.toggleDialogVisible('details', true); },"rejectCallback":_vm.showAuthDialog}},[(_vm.visibleSaveBtn && _vm.canUpdateUserGroup)?_c('pt-button',{attrs:{"size":"medium","loading":_vm.saving,"disabled":!_vm.usersCanSave}},[_vm._v(" "+_vm._s(_vm.$t('users.save_new_users'))+" ")]):_vm._e()],1)],1)],1),_c('div',{class:_vm.$style.body},[_c('div',{class:_vm.$style.container},[_c('filter-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterVisible),expression:"filterVisible"}],attrs:{"filterVisible":_vm.filterVisible,"userGroupId":_vm.userGroupId,"filter":_vm.usersFilter,"filterTmp":_vm.userGroupInfoTmp && _vm.userGroupInfoTmp.content},on:{"unfold":_vm.handleFilterUnfold,"change":_vm.handleFilterChange}}),_c('div',{class:['x-wrap', 'x-wrap--full', _vm.$style.tabs]},[_c('pt-tabs',{attrs:{"tabs":_vm.tabOptions,"label":"code","theme":"blue","size":"large"},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('router-link',{attrs:{"to":{ name: slotProps.tab.routerName, params: _vm.$route.params }}},[_vm._v(" "+_vm._s(_vm.$t(slotProps.tab.name))+" ")])]}}],null,false,626869567),model:{value:(_vm.currentTabCode),callback:function ($$v) {_vm.currentTabCode=$$v},expression:"currentTabCode"}})],1)],1),_c('keep-alive',[_c('router-view')],1)],1)]:_vm._e(),(_vm.dialogVisible.details)?_c('details-dialog',{attrs:{"userGroupInfo":_vm.userGroupInfo},on:{"cancel":function($event){return _vm.toggleDialogVisible('details', false)},"confirm":_vm.handleConfirmSave}}):_vm._e(),(_vm.dialogVisible.backDialog)?_c('back-dialog',{on:{"cancel":function($event){return _vm.toggleDialogVisible('backDialog', false)},"confirm":_vm.handleConfirmBack}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }